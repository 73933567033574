import React from 'react'
import { Link } from 'gatsby'

export default function Header() {
    return (
        <div>
            <div
                className="mx-auto flex flex-row items-center
             top-0 w-screen 
             px-4 pt-8 pb-0 md:pt-16 md:pb-16"
            >
                <Link
                    to="/"
                    className="text-2xl sm:text-3xl font-semibold flex-1 font-serif mr-8"
                >
                    Preble Law Firm{' '}
                    <span className="hidden md:inline lg:inline">PC, LLO</span>
                </Link>

                {/* <Link
                    to="/firm"
                    className="hidden md:block mx-2 md:mr-4 border-b-2
                        border-transparent hover:border-gold"
                >
                    Services
                </Link> */}
                <Link
                    to="/about"
                    className="hidden md:block mr-4 border-b-2
                    border-transparent hover:border-gold"
                >
                    About
                </Link>
                <Link
                    to="/contact"
                    className="hidden md:block mr-4 border-b-2
                    border-transparent hover:border-gold"
                >
                    Contact
                </Link>
                <p
                    className="px-4 py-2  md:mr-4 rounded-full 
                        cursor-pointer font-semibold
                        text-white text-sm bg-gold hover:bg-gold-darker"
                >
                    (402) 467-4270
                </p>
            </div>
            <div className="mx-auto visible md:hidden flex flex-row px-4 pb-8 pt-4">
                {/* <Link to="/" className="mr-4">
                    Services
                </Link> */}
                <Link to="/about" className="mr-4">
                    About
                </Link>
                <Link to="/contact">Contact</Link>
            </div>
        </div>
    )
}
