/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import './layout.css'
import Header from './Header'

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    useEffect(() => {
        let onScroll = evt => {
            // console.log(window.pageYOffset)
        }

        let listener = window.addEventListener('scroll', onScroll)
        return () => window.removeEventListener('scroll', onScroll)
    })

    return (
        <div
            className="flex flex-col"
            style={{
                minHeight: '100vh',
            }}
        >
            <Header siteTitle={data.site.siteMetadata.title} />
            <div className="h-full flex flex-col flex-1">
                <main className="flex-1 px-4 mx-auto w-full">{children}</main>
                <footer className="bg-black text-white p-8 py-12 flex">
                    <div className="mw-auto" style={{ maxWidth: '1080px' }}>
                        <div className="max-w-sm">
                            <p>
                                © {new Date().getFullYear()}, Preble Law Firm,
                                PC, LLO
                            </p>
                            <p className="text-xs text-gray-300 mt-4">
                                All materials contained on this website are made
                                available by Preble Law Firm, PC, LLO for
                                informational purposes only and should not be
                                construed as legal advice. The transmission and
                                receipt of information contained on this website
                                does not form or constitute an attorney-client
                                relationship. Persons should not act upon
                                information found on this website without first
                                seeking professional legal counsel.
                            </p>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
