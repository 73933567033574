import React from 'react'
import Layout from '../Components/Layout'
import SEO from '../Components/seo'
const headerImage = require('../images/hero.jpg')

const ContactPage = () => (
    <Layout>
        <SEO title="Contact" />
        <div className="relative mx-auto flex align-center justify-center">
            <img
                className="bg-transparent block rounded-lg w-full mx-auto object-cover object-center z-0"
                alt="header"
                style={{ height: '30vh' }}
                src={headerImage}
            />
            <div className="absolute inset-0 opacity-50 bg-black z-10" />
            <div
                className="absolute inset-0 flex flex-col w-full 
                 z-30 justify-center align-center"
            >
                <h1
                    className="w-full text-5xl text-center 
                        font-semibold text-white font-serif"
                >
                    Contact Us
                </h1>
            </div>
        </div>
        <div className="container flex flex-col md:flex-row align-center mx-auto pt-16 pb-32">
            <div className="w-1/2">
                <h1 className="font-serif text-4xl">Contact us</h1>

                <span className="block text-gray-700 font-bold mt-8">
                    Phone
                </span>
                <h3
                    className="font-serif text-2xl mt-4 
                            text-gold underline cursor-pointer"
                >
                    (402) 467-4270
                </h3>

                <span className="block text-gray-700 font-bold mt-8">Fax</span>
                <h3 className="font-serif text-2xl mt-4">(402) 475-7989‬</h3>

                <span className="block text-gray-700 font-bold mt-8">
                    Email
                </span>
                <h3 className="font-serif text-2xl">
                    tpreble@preblelawfirm.com
                </h3>
            </div>
            <div className="w-1/2">
                <h1 className="font-serif text-4xl hidden md:block">&nbsp;</h1>

                <span className="block text-gray-700 font-bold mt-8">
                    Address
                </span>
                <h3 className="font-serif text-2xl">
                    1023 Lincoln Mall, Suite 101
                    <br />
                    Lincoln, NE 68508
                </h3>
                <p className="font-serif">(Parking located behind building)</p>

                <span className="block text-gray-700 font-bold mt-8">
                    Hours
                </span>
                <h3 className="font-serif text-2xl">Monday-Friday: 8am-5pm</h3>
                <p className="font-serif">(Other hours by appointment)</p>
            </div>
        </div>
        {/* <h1>Hi from the second page</h1>
        <p>Welcome to page 2</p>
        <Link to="/">Go back to the homepage</Link> */}
    </Layout>
)

export default ContactPage
